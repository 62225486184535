
  import {Component, Prop, Vue, Emit} from 'vue-property-decorator';
  import api from '@/infrastructure/api/API';
  import {HeadOfficeCode, Office} from '@/types/office/Office';

  @Component({})
  export default class OfficesSelector extends Vue {
    // data
    offices: Office[] = [];

    // prop
    @Prop({default: null})
    value!: string | null;
    @Prop({default: false})
    disabled !: boolean;

    // computed
    get officeList(): Office[] {
      return this.offices.filter((it) => it.code !== HeadOfficeCode);
    }

    get contact(): string {
      if (!this.value) {
        return '　';
      }
      const office = this.officeList.find((it) => it.code === this.value);
      if (!office) {
        return '';
      }
      return '支所のお問い合わせ先 -> ' + office.contact;
    }

    // method
    async initialize(): Promise<void> {
      this.offices = await api.getOffices();
    }

    @Emit('input')
    update(event: Event): string | null {
      const target = event.target as HTMLInputElement;
      if (target.value) {
        return target.value as string;
      }
      return null;
    }

    // lifecycle hooks
    created(): void {
      this.initialize();
    }
  }
