
  import {Component, Vue} from 'vue-property-decorator';
  import {progress} from '@/infrastructure/script/Progress';
  import OfficesSelector from '@/components/molecules/selector/OfficesSelector.vue';
  import api from '@/infrastructure/api/API';
  import {EmptyInquiryRegisterRequest, InquiryRegisterRequest} from '@/types/inquiry/Inquiry';
  import {InquiryType} from '@/types/inquiry/InquiryType';
  import {loginModule} from '@/stores/authentication/Account';

  @Component({
    components: {
      OfficesSelector,
    },
  })
  export default class InquiryConfirm extends Vue {
    // data
    messages: string[] = [];

    // computed
    get assessorNumber(): string {
      return loginModule.assessorNumber;
    }

    get hasError(): boolean {
      return this.messages.length > 0;
    }

    get request(): InquiryRegisterRequest {
      if (sessionStorage.hasOwnProperty('inquiry-form')) {
        return JSON.parse(sessionStorage.getItem('inquiry-form') as string);
      }
      return EmptyInquiryRegisterRequest;
    }

    get inquiryType(): string {
      if (this.request.inquiryType === InquiryType.ToSystem) {
        return 'システムに関するお問い合わせ';
      }
      return 'その他お問い合わせ';
    }

    // methods
    async inquire(): Promise<void> {
      await this.register();
      sessionStorage.removeItem('inquiry-form');
      this.toInquiry();
    }

    async register(): Promise<void> {
      const registerInquiry = async (): Promise<void> => {
        await api.registerInquiry(this.request)
          .catch((error: any) => {
            if (error.data.message) {
              this.messages = error.data.message;
              return;
            }
          });
      };
      await progress(registerInquiry);
    }

    toInquiry(): void {
      this.$router.push({path: '/inquiry'}).catch(() => {
        // @ts-ignore block is empty.
      });
    }

    back(): void {
      this.$router.push({path: '/inquiry/form'}).catch(() => {
        // @ts-ignore block is empty.
      });
    }
  }
