import {InquiryStatus} from '@/types/inquiry/InquiryStatus';
import {InquiryType} from '@/types/inquiry/InquiryType';
import moment from 'moment';
import {SortOrderRequest} from '@/types/sort/SortOrder';

export interface InquirySummary {
  seq: number;
  type: InquiryType;
  inquiresAt: Date;
  title: string;
  status: InquiryStatus;
}

export interface Inquiry {
  seq: number;
  type: InquiryType;
  inquiresAt: Date;
  title: string;
  content: string;
  status: InquiryStatus;
  answer: InquiryAnswer;
}

export const EmptyInquiry: Inquiry = {
  seq: 0,
  type: InquiryType.ToOffice,
  inquiresAt: moment().toDate(),
  title: '',
  content: '',
  status: InquiryStatus.Inquiry,
  answer: {content: ''},
};

export interface InquiryAnswer {
  content: string;
}

export interface InquiryRegisterRequest {
  inquiresAt: Date;
  inquiryType: InquiryType;
  title: string;
  content: string;
}
export interface InquiryAnswerRegisterRequest {
  answersAt: Date;
  title: string;
  inquirySeq: number;
  content: string;
}
export const EmptyInquiryAnswerRegisterRequest: InquiryAnswerRegisterRequest = {
  answersAt: moment().toDate(),
  title: '',
  inquirySeq: 0,
  content: '',
};
export const EmptyInquiryRegisterRequest: InquiryRegisterRequest = {
  inquiresAt: moment().toDate(),
  inquiryType: InquiryType.ToSystem,
  title: '',
  content: '',
};

export interface InquiriesGetRequest {
  inquiryTypes: InquiryType[] | null;
  sorts: SortOrderRequest[];
}

export interface InquiryGetRequest {
  inquirySeq: number;
}
